<template>



  <div class="hello">
    <!-- <h1>{{ msg }}</h1> -->

    <p class="copyright">
      © Liam O'Halloran. All rights reserved. 
      

    </p>

<div class="container-sm">
<p>

  <button class="btn btn-primary btn-margin-ctrl" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
    Contact to Purchase
  </button>
</p>
<div class="collapse " id="collapseExample">
  <div class="col-lg-6 center">
    <form
    name="ask-question"
    method="post"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    >
    <input type="hidden" name="form-name" value="ask-question" />
    <div class="mb-3">
      <textarea class="form-control" id="textAreaExample" rows="4" name="message">Hello Liam, I would like to make arrangement to purchase...</textarea>
      <label class="form-label" for="textAreaExample">Purchasing Request Message</label>
      <!-- <label for="message" class="form-label">Hello Liam, I would like to make arrangement to purchase...</label>
      <input type="password" class="form-control" id="message"> -->
    </div>

    <div class="mb-3">
      <label for="inputEmail" class="form-label"> Email address</label>
      <input type="email" class="form-control " id="inputEmail1" aria-describedby="emailHelp" name="inputemail">

    </div>
        <div class="mb-3">
      <label for="phoneinput" class="form-label" >Phone Number</label>
      <input type="tel" class="form-control " id="phoneinput" aria-describedby="emailHelp" name="phonenumber">
      
    </div>
    <div id="nevershare" class="form-text">We'll never share your contact details with anyone else.</div>

    <button type="submit" class="btn btn-primary">Submit</button>
  </form>
  </div>
</div>
</div>

    
<div class="container-md center zero-margin">


      <div class="row picture ">
        <div class="row " >
         
            <div class="col-lg-10 center zero-margin">
                <img src="../assets/tui.jpg" class="w-100 zero-margin" alt="...">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-10 center zero-margin ">
                <div class='blurb w-100'>
                  <h5>Tui</h5>
                A mixed media portrait of a New Zealand iconic bird.<br>
                Using acrylic paint and white charcoal.<br>
                Originally on paper.
                </div>
            </div>   
          </div>   
      </div>
      

      <div class="row picture">
        <div class="row">
         
            <div class="col-lg-10 center  zero-margin">
                <img src="../assets/crow.jpg" class="d-block w-100" alt="...">
            </div>
        </div>
        <div class="row">
          <div class="col-lg-10 col-md-12 center  zero-margin">
              <div class='blurb d-block w-100'>
                <h5>Crow</h5>
              Used thin layers of acrylic paint and white charcoal pencil.
              </div>
          </div>   
        </div>   
      </div>
  
      <div class="row picture">
        <div class="row">
            <div class="col-lg-10 center zero-margin">
                <img src="../assets/falcon-on-the-grass.jpg" class="d-block w-100" alt="...">
            </div>
        </div>
        <div class="row">
          <div class="col-lg-10 center  zero-margin">
              <div class='blurb d-block w-100'>
                <h5>Falcon on the Grass</h5>
              A mixture of a blue green pan pastel background.<br>
              Pastel pencils, white charcoal pencil and acrylic paint.
              </div>
          </div>   
        </div>  
      </div> 
  
      <div class="row picture">
        <div class="row">
            <div class="col-lg-10 center zero-margin">
                <img src="../assets/falcon-by-the-sea.jpg" class="d-block w-100" alt="...">
            </div>
        </div>
        <div class="row">
          <div class="col-lg-10 center zero-margin ">
              <div class='blurb d-block w-100'>
                <h5>Falcon by the Sea</h5>
              Acrylic paint on card.<br>
              </div>
          </div>   
        </div>  
      </div> 

        
      <div class="row picture">
        <div class="row">
            <div class="col-lg-10 center zero-margin">
                <img src="../assets/kingfisher-on-wood.jpg" class="d-block w-100" alt="...">
            </div>
        </div>
        <div class="row">
          <div class="col-lg-10 center  zero-margin">
              <div class='blurb d-block w-100'>
                <h5>Kingfisher on Wood</h5>
              Here on the Kingfisher I have used pastel pencil, and also white charcoal pencil to bring out the details, and acrylic paint.
              </div>
          </div>   
        </div>  
      </div> 

   
        
      <div class="row picture">
        <div class="row">
            <div class="col-lg-10 center zero-margin">
                <img src="../assets/kokako.jpg" class="d-block w-100" alt="...">
            </div>
        </div>
        <div class="row">
          <div class="col-lg-10 center  zero-margin">
              <div class='blurb d-block w-100'>
                <h5>Kokako</h5>
                The Kokako is a rare New Zealand bird of which there are two distinct varieties:<br>
                The North Island variety has a blue wattle, whilst the South Island variety has an orange wattle.


              </div>
          </div>   
        </div>  
      </div>   


      <div class="row picture">
        <div class="row">
            <div class="col-lg-10 center zero-margin">
                <img src="../assets/bell-bird.jpg" class="d-block w-100" alt="...">
            </div>
        </div>
        <div class="row">
          <div class="col-lg-10 center  zero-margin">
              <div class='blurb d-block w-100'>
                <h5>Bell Bird</h5>

              </div>
          </div>   
        </div>  
      </div>   

        
      <div class="row picture">
        <div class="row">
            <div class="col-lg-10 center zero-margin">
                <img src="../assets/quad.jpg" class="d-block w-100" alt="...">
            </div>
        </div>
        <div class="row">
          <div class="col-lg-10 center  zero-margin">
              <div class='blurb d-block w-100'>
                <h5>Quad Collage</h5>

              </div>
          </div>   
        </div>  
      </div>   








      <div class="row footer copyright">
        <p>
          <br>
          All images are the exclusive<br>intellectual property of Liam O'Halloran.<br><br>
          © Liam O'Halloran. All rights reserved. 
        </p>
      </div>

    </div>
    
  </div>



 

</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h3 {
  margin: 40px 0 0;
} */
ul {
  list-style-type: none;
  padding: 0;
}
/* li {
  display: inline-block;
  margin: 0 10px;
} */
a {
  color: #42b983;
}



.center {
  margin: auto;
  /* width: 50%; */
  /* border: 3px solid green; */
  /* padding: 10px; */
}

.blurb{
  width: 100;
  background-color: rgb(222, 226, 226);
  padding:0.5em;
  /* padding: 3em; */
}



.row{
  /* margin-left: 0px;
  margin-right: 0px; */
  margin: 0px;
  padding: 0px;

}

.zero-margin{
  /* margin-left: 0px;
  margin-right: 0px; */
  padding: 0px;
}

.picture{

  margin-top: 3em;
}

.copyright{
  margin-top: 0px;
  font-size: 12px;
}

.footer{
  margin-top: 2em;
  
}

.btn-margin-ctrl{
  margin-bottom: 0px;
}


</style>
