<template>
 
 <div class="header-block d-block w-100">
    <div class="main-header">
      <h1>Layer One Art</h1>
    </div>
    
    <div class="sub-header">
      <!-- <h2>A collection of the original artworks</h2> -->
        <h3>The artworks of Liam O'Halloran </h3>
        <h5>(New Zealand).</h5>
    </div>
  </div>




  <HelloWorld msg="HEllo WorlD"/>
  
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  background-color: rgb(239, 241, 241);
}

.header-block{
  margin-bottom: 0.3em;;
  background-color: rgb(222, 226, 226);
  padding:0.3em;
}
</style>
